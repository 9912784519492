import './Main.css'
const PageNotFound=()=>{


    return (

        <>
        <h1 className="not_found">404 Page Not Found</h1>
        </>
    )
}


export default PageNotFound;