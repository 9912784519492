import Addtocartprovider from "../context/Addtocartcontext.js";
import Addtocart from "./Addtocart.js";
import ProductList from "./CardList.js";
import Header from "./Header.js";

const Componentaddtocart=()=>{
return(

    <>

    <Header/>
    <Addtocart/>

    </>
)


}

export default Componentaddtocart;